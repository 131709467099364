import { Dropdown, TextField } from "@fluentui/react";
import * as React from "react";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { ParagraphLines } from "../../../html-constants/styles/html-styles";
import { AnalysisRecommendation, AttackVectorCategory, IOCType, MITREAttack, Keys } from "../../../lib/enums/common-enums";
import { IExtensionAnalysisSubmitProps } from "../../../lib/interfaces/common-interface";
import { DropdownOptionsGenerator, IsValidChromeStoreUrl, IsValidUrl } from "../../../lib/utils/util";
import { SearchableMultiselectDropdown } from "../../common-form-componnets/searchable-dropdown/searchable-multselect-dropdown";
import useDropdown from "../../hooks/useDropdown";
import useDropdownMultiSelect from "../../hooks/useDropdownMultiSelect";
import useInput from "../../hooks/useInputValidation";
import SectionTitleHelperText from "../../view/section-title-helper-text/section-title-helper-text";
import {
  AttackVectorDropdownOptions,
  IOCTypeDropdownOptions,
  MaliciousDropdownOptions,
  RecommendationDropdownOptions,
  ReproducibleDropdownOptions,
} from "../dropdown-options";
import "./extension-analysis-submit.scss";

export default function ExtensionAnalysisSubmit(props: IExtensionAnalysisSubmitProps): React.ReactElement {
  const reproducible = useDropdown(
    props.initialData.isReproducible ? localeStrings[`Yes`] : localeStrings[`No`],
    ReproducibleDropdownOptions,
    true,
  );
  const malicious = useDropdown(
    props.initialData.isMalicious ? localeStrings[`Yes`] : localeStrings[`No`],
    MaliciousDropdownOptions,
    true,
  );
  const [additionalAnalysisDetailsRequired, setadditionalAnalysisDetailsRequired] = React.useState<boolean>(false);
  const ttp = useInput(props.initialData.ttp, additionalAnalysisDetailsRequired);
  const iocTypes = useDropdown(props.initialData.iocType, IOCTypeDropdownOptions, true);
  const iocValue = useInput(props.initialData.iocValue, true);
  const attackVectorCategory = useDropdownMultiSelect(
    props.initialData.attackVectorCategories,
    AttackVectorDropdownOptions,
    { key: Keys.NoneAttackVectorCategory, text: AttackVectorCategory.None },
    true,
  );
  const recommendation = useDropdown(props.initialData.recommendation, RecommendationDropdownOptions, true);
  const comments = useInput(props.initialData.comments, true);
  const vulnerability = useInput<React.FormEvent<HTMLInputElement>, string>(
    props.initialData.vulnerability,
    false,
  );
  const policyFailure = useInput<React.FormEvent<HTMLInputElement>, string>(
    props.initialData.policyFailure,
    additionalAnalysisDetailsRequired,
  );
  const daasLogUrl = useInput(props.initialData.daasOutputLogFile, false, localeStrings[`InvalidUrl`], IsValidUrl);
  const chromeStoreUrl = useInput(
    props.initialData.chromeStoreUrl,
    false,
    localeStrings[`InvalidChromeStoreUrl`],
    IsValidChromeStoreUrl,
  );

  const isMaliciousValidInput = (): boolean => {
    let isValid: boolean = true;
    if (malicious.selectedItem === localeStrings[`Yes`]) {
      isValid = policyFailure.value.trim().length > 0
        && ttp.value.trim().length > 0
    }
    return isValid;
  };

  const isIOCValidInput = (): boolean => {
    let isValid: boolean = false;
    if (malicious.selectedItem) {
      isValid = iocTypes.selectedItem &&
           iocValue.value.trim().length> 0
    }
    return isValid;
  };

  const rulesDropdown = useDropdownMultiSelect(
    (props?.initialData?.detectedRules || []).concat(props?.initialData?.reportedRules || []),
    DropdownOptionsGenerator(props.allRules),
    { key: Keys.NoneAttackVectorCategory, text: AttackVectorCategory.None },
    recommendation.selectedKey === Keys.FailAnalysisRecommendation
    || recommendation.selectedKey === Keys.RevokeAnalysisRecommendation
    || recommendation.selectedKey === Keys.TakedownAnalysisRecommendation,
    localeStrings[`RuleSelectError`]);

    React.useEffect(() => {
    if (
      reproducible.isValidInput &&
      malicious.isValidInput &&
      attackVectorCategory.isValidInput &&
      comments.isValidInput &&
      recommendation.isValidInput &&
      chromeStoreUrl.isValidInput &&
      daasLogUrl.isValidInput &&
      isMaliciousValidInput() &&
      isIOCValidInput()
    ) {
      props.setData({
        attackVectorCategories: attackVectorCategory.selectedItems as AttackVectorCategory[],
        comments: comments.value,
        isMalicious: (malicious.selectedItem as unknown) as boolean,
        ttp: ttp.value,
        iocType: (iocTypes.selectedItem !== undefined) ? iocTypes.selectedItem as IOCType : "" as IOCType,
        iocValue: iocValue.value,
        isReproducible: (reproducible.selectedItem as unknown) as boolean,
        mitreAttack: MITREAttack.BrowserExtensions,
        policyFailure: policyFailure.value,
        recommendation: recommendation.selectedItem as AnalysisRecommendation,
        vulnerability: vulnerability.value,
        isActiveInChromeStore: !!chromeStoreUrl.value,
        chromeStoreUrl: !!chromeStoreUrl.value ? chromeStoreUrl.value : "",
        isDaasPerformed: !!daasLogUrl.value,
        daasOutputLogFile: !!daasLogUrl.value ? daasLogUrl.value : "",
        reportedRules: rulesDropdown.selectedItems
      });
    } else {
      props.setData(null);
    }
  }, [
    reproducible.selectedKey,
    malicious.selectedKey,
    ttp.value,
    iocTypes.selectedKey,
    iocValue.value,
    attackVectorCategory.selectedKeys,
    comments.value,
    vulnerability.value,
    policyFailure.value,
    recommendation.selectedKey,
    chromeStoreUrl.value,
    daasLogUrl.value,
    chromeStoreUrl.isValidInput,
    daasLogUrl.isValidInput,
    rulesDropdown.selectedKeys
    ]);

  React.useEffect(() => {
    setadditionalAnalysisDetailsRequired(
      malicious.selectedItem !== localeStrings[`No`],
    );
  }, [malicious.selectedKey]);

  React.useEffect(() => {
    chromeStoreUrl.setRequired(!!chromeStoreUrl.value);
    daasLogUrl.setRequired(!!daasLogUrl.value);
  }, [chromeStoreUrl.value, daasLogUrl.value]);

  return (
    <div className={"threat-analysis"}>
      <SectionTitleHelperText
        title={localeStrings[`ExtensionAnalysis_SectionTitle`]}
        helperText={localeStrings[`ExtensionAnalysis_SectionHelpText`]}
        linkText={localeStrings[`LearnMoreText`]}
        link={WikiLink}
      />
      <div className="content">
        <Dropdown
          placeholder={localeStrings[`SelectAnOption`]}
          label={localeStrings[`ExtensionAnalysis_Reproducible`]}
          {...reproducible}
        />
        <Dropdown
          placeholder={localeStrings[`SelectAnOption`]}
          label={localeStrings[`ExtensionAnalysis_Malicious`]}
          {...malicious}
        />
            <TextField
              label={localeStrings[`ExtensionAnalysis_TTP`]}
              {...ttp}
              required={additionalAnalysisDetailsRequired}
            />
            < Dropdown
              label={localeStrings[`ExtensionAnalysis_IOCType`]}
              {...iocTypes}
              placeholder={localeStrings[`SelectAnOption`]}
            />
            <TextField
              className="text-box"
              label={localeStrings[`ExtensionAnalysis_IOCValue`]}
              {...iocValue}
              rows={ParagraphLines}
              multiline
            />
        <Dropdown
          className="attack-vector-dropdown"
          placeholder={localeStrings[`SelectAnOption`]}
          label={localeStrings[`ExtensionAnalysis_AttackVectorCategory`]}
          multiSelect
          {...attackVectorCategory}
        />
        <Dropdown
          placeholder={localeStrings[`SelectAnOption`]}
          label={localeStrings[`ExtensionAnalysis_Recommendation`]}
          {...recommendation}
        />
        <TextField label={localeStrings[`ExtensionAnalysis_ChromeStoreUrl`]} {...chromeStoreUrl} />
        <TextField label={localeStrings[`ExtensionAnalysis_DaasLogUrl`]} {...daasLogUrl} />
        <SearchableMultiselectDropdown
          multiSelect={true}
          {...rulesDropdown}
          label={localeStrings[`RulesSelect`]}
        />
        <TextField
          label={localeStrings[`ExtensionAnalysis_MITREAttack`]}
          disabled
          value={MITREAttack.BrowserExtensions}
        />
        <TextField
          className="text-box"
          label={localeStrings[`ExtensionAnalysis_FailedOnPolicy`]}
          rows={ParagraphLines}
          multiline
          {...policyFailure}
          required={additionalAnalysisDetailsRequired}
        />
        <TextField
          className="text-box"
          label={localeStrings[`ExtensionAnalysis_Vulnerability`]}
          rows={ParagraphLines}
          multiline
          {...vulnerability}
        />
      </div>
      <TextField
        className="comment-box"
        label={localeStrings[`ExtensionAnalysis_Comments`]}
        rows={ParagraphLines}
        multiline
        value={""}
        {...comments}
      />
    </div>
  );
}
